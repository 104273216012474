import queryString from 'querystring';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import Dropdown from '../Dropdown';
import './style.scss';
const TranslateLanguage = ({
  languages,
  activeLanguage,
  setActiveLanguage,
  ...props
}) => {
  let query = queryString.parse(props.location.search.slice(1, props.location.search.length));
  useEffect(() => {
    let langCode = localStorage.getItem('languageCode');
    if (langCode === 'kr' || langCode === 'en') {
      setActiveLanguage(langCode);
    }

    if (props.location.search && props.location.search != '') {
      if (query && query.lang) {
        if (query.lang === 'kr' || query.lang === 'en') {
          setActiveLanguage(query.lang);
          localStorage.setItem('languageCode', query.lang);
          props.history.push(props.location.pathname);          
        } 
      } 
    }

    // const selectLanguage = document.getElementsByTagName("SELECT");
    // selectLanguage[0].setAttribute('title', translate('webAccessibility.selectLanguage'));
    // console.log('----------',document.getElementsByTagName("LABEL"));
    // let label = document.getElementsByTagName("LABEL")[0];
    // if (label.parentNode) {
    //   label.parentNode.removeChild(label);
    // }
  }, []);
  
  const languageOptions = languages.map((item, index) => {
    return { id: index, value: item.name };
  });
  
  const onLanguageChange = value => {
    console.log('value', languages, value);
    const languageCode = languages.find(({name}) => name === value)?.code;
    if(!languageCode) {
      throw Error(`ERROR onLanguageChange : ${value} 값에 해당하는 languageCode를 찾을 수 없습니다.`);
    }
    setActiveLanguage(languageCode);
    localStorage.setItem('languageCode', languageCode);
    
    setTimeout(() => {      
      const webFirstLink = document.querySelector('.skip-link');
      if(webFirstLink) {
        webFirstLink.focus();
      }
    }, 100);
  };
  
  return (
    <>
      <Helmet htmlAttributes={{ lang : activeLanguage.code === 'kr' ? 'ko' : activeLanguage.code }}/>
      <Dropdown id="footer-dropdown" data={languageOptions} onClick={onLanguageChange} colorMode='white' menuOpenDir='top' className="footer-dropdown" />
    </>
  );
};

export default withRouter(withLocalize(TranslateLanguage));
