import { focusReturnModalClose } from '../../../utils/webAccess';

const delay = (number) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(true);
    }, number);
  });
};

/**
 * actions 영역에 있는 preview 버튼과 동일한 역활을 하지만 화면에 노출되고 있지 않는 버튼으로 포커스 되지 않도록 설정하고 aria-hidden 처리
 */
const hiddenFocusThumbLink = (crrItem) => {
  const thumbLink = crrItem.querySelector('.ant-upload-list-item-info a');
  if(!thumbLink) return;
  thumbLink.setAttribute('tabindex', -1);
  thumbLink.setAttribute('aria-hidden', true);
};

/**
 * actionsWrap 및 하위 버튼들 tab control 위해 tabindex 추가
 */
const setupActionsWrapFocus = (actionsWrap) => {
  // actions영역에 focus 되었을때 하위 button들 노출되도록 tabindex 추가
  actionsWrap.setAttribute('tabindex',0);
  // delete 버튼은 a 태그 없이 i태그로만 노출되고 있어 포커스 되도록 tabindex 추가
  actionsWrap.querySelector('.anticon-delete').setAttribute('tabindex',0);
};

/**
 * actionsWrap 및 하위 삭제버튼에 타이틀과 라벨 제거
 */
const deleteButtonRemoveTitleLabel = (actionsWrap) => {
  actionsWrap.querySelector('.anticon-delete').removeAttribute('title');
  actionsWrap.querySelector('.anticon-delete').removeAttribute('aria-label');
};

/**
 * 미리보기버튼 target="_blank" 삭제
 */
const modalButtonRemoveTarget = (actionsWrap)=> {
  const labelledEls = actionsWrap.querySelectorAll('[aria-label]');
  labelledEls.forEach(labelledEl => {
    const crrLabel=labelledEl.getAttribute('aria-label');
    if(!crrLabel.includes('icon:')) return;
    if(crrLabel === 'icon: eye-o'){
      labelledEl.parentNode.removeAttribute('target');
    }
  });
};

/**
 * 잘못된 aria label 재설정
 */
const changeBadAriaLabels = (actionsWrap)=> {
  const labelledEls = actionsWrap.querySelectorAll('[aria-label]');
  labelledEls.forEach(labelledEl => {
    const crrLabel=labelledEl.getAttribute('aria-label');
    if(!crrLabel.includes('icon:')) return;
    let ariaLabel = null;
    switch(crrLabel) {
      // icon: picture label을 가진 요소는 화면에서 확인되지 않으나, 잘못된 라벨이기에 라벨값 제거
      // 추후 해당 요소가 확인된다면 올바른 label값 설정하기를 권장
      case 'icon: picture':
        labelledEl.removeAttribute('aria-label');
        break;
      case 'icon: eye-o':
        ariaLabel = 'Preview file';
        break;
      /*case 'icon: delete':
        ariaLabel = '삭제';
        break;*/
    }
    if(!ariaLabel) return;
    labelledEl.setAttribute('aria-label', ariaLabel);
  });
};

/**
 * image preview 모달 종료시 포커스 다시 preview 버튼으로 돌아가도록 설정
 */
const imagePreviewPopupFocusControl = (actionsWrap) => {
  const imagePreviewButton = actionsWrap.children[0];
  console.log(imagePreviewButton);
  if(!imagePreviewButton) return;
  imagePreviewButton.onclick = (() => {
    focusReturnModalClose({el: imagePreviewButton});
  });
};

const imagePreviewPopupCloseChangeBadAriaLabels = () =>{
  const popupCloseButton = document.querySelectorAll('.ant-modal-close-icon');
  popupCloseButton.forEach(labelledEl => {
    console.log(labelledEl);
    labelledEl.setAttribute('aria-label', '닫기');
  });
}

/**
 * antd <Upload> 컴포넌트 접근성에 위배되는 부분 수정해주는 함수
 */
export const setupForWebAccess = async () => {
    await delay(60);
    const uploadedItems = document.querySelectorAll('.ant-upload-list-item');
    if(uploadedItems.length === 0) return;
    // 새로 추가된 img
    const crrItem = uploadedItems[uploadedItems.length - 1];
    const actionsWrap = crrItem.querySelector('.ant-upload-list-item-actions');
    if(!crrItem||!actionsWrap) return;
    hiddenFocusThumbLink(crrItem);
    setupActionsWrapFocus(actionsWrap);
    deleteButtonRemoveTitleLabel(actionsWrap);
    modalButtonRemoveTarget(actionsWrap);
    changeBadAriaLabels(actionsWrap);
    imagePreviewPopupFocusControl(actionsWrap);
    imagePreviewPopupCloseChangeBadAriaLabels();
};
