import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';

import moment from 'moment';
import BannerContent from '../../components/BannerContent';
import BreadCrumb from '../../components/BreadCrumb';
import Dropdown from '../../components/Dropdown';
import LayoutPrivacy from '../../hocs/LayoutPrivacy';
import {
  apiContentPrivacyById,
  apiListContentPrivacy,
  apiListPublicDate
} from '../../services/api/index';
import './style.scss';

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      privacy: null,
      firstContent: [],
      listPublicDate: [
        {
          key: 1,
          id: moment().format('DD-MMM-YYYY'),
          publicDate: moment().format('DD-MMM-YYYY')
        }
      ],
      selectBoxVal: [moment().format('DD-MMM-YYYY')],
      classActive: null,
      selectBoxActive: null
    };
  }

  componentDidMount() {
    this.loadPublicDate(this.props);  
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activeLanguage.code !== nextProps.activeLanguage.code) {
      this.loadPublicDate(nextProps);
    }
  }

  loadPublicDate = async props => {
    let data = await apiListContentPrivacy(props.activeLanguage.code);
    if (data && data.data.data.contentsList.length > 0) {
      let contentId = data.data.data.contentsList[0].id;
      if (contentId) {
        this.setState({
          privacy: data.data.data.contentsList[0]
        });

        let result = await apiListPublicDate(
          props.activeLanguage.code,
          contentId
        );

        if (result.data && result.data.data.publicDateList.length > 0) {
          this.setState({
            listPublicDate: this.convertPublicDate(
              result.data.data.publicDateList
            ),
            selectBoxVal: result.data.data.publicDateList[0].publicDate,
            classActive: 'sb_Inactive'
          });
          let id = result.data.data.publicDateList[0].id;
          let publicDate = result.data.data.publicDateList[0].publicDate;
          apiContentPrivacyById(publicDate, props.activeLanguage.code).then(
            res => {
              this.setState({
                privacy: res.data.data,
                selectBoxVal: publicDate
              });
            }
          );
        } else {
          this.setState({
            classActive: 'sb_Active',
            listPublicDate: [
              {
                key: 1,
                id: moment().format('DD-MMM-YYYY'),
                publicDate: moment().format('DD-MMM-YYYY')
              }
            ],
            selectBoxVal: []
          });
        }
      } else {
        this.setState({
          privacy: null
        });
      }
    } else {
      this.setState({
        privacy: null
      });
    }
  };
  onChange = (value, key) => {
    let id;
    let publicDate = value;
    this.state.listPublicDate.map(el => {
      if (el.publicDate == value) {
        id = el.key;
        return true;
      }
    });
    apiContentPrivacyById(publicDate, this.props.activeLanguage.code).then(
      res => {
        this.setState({
          privacy: res.data.data,
          selectBoxVal: value
        });
      }
    );
    
    setTimeout(() => {      
      const titleEl = document.querySelector('.pp-title');
      if(titleEl) {
        titleEl.setAttribute('tabindex', 0);
        titleEl.focus();
      }
    }, 100);
  };
  convertPublicDate = data => {
    let newData = [];

    data.map(el => {
      let strDate = el.publicDate;
      if (this.props.activeLanguage.code === 'en') {
        let mMoment = moment.utc(el.publicDate, 'DD-MM-YYYY');
        strDate = mMoment.format('DD-MMM-YYYY');
      }
      let newEl = {};
      newEl.key = el.id;
      newEl.id = el.publicDate;
      newEl.publicDate = strDate;
      newData.push(newEl);
    });
    return newData;
  };
  renderContent = () => {
    let { privacy } = this.state;
    const { activeLanguage, translate } = this.props;
    if (privacy) {
      return (
        <section className="pp-post">
          <h2 className="pp-title">{`${privacy.title}`}</h2>
          <div className="pp-horizon-rule"></div>
          <div className="pp-post-content">
            <div dangerouslySetInnerHTML={{ __html: privacy.content }}></div>
          </div>
        </section>
      );
    } else {
      if (activeLanguage.code === 'kr') {
        return (
          <div className="privacy_content">
            <div className='pp_child_wrap'>
              <b className="pp_child_title">1. 총칙</b>
              <p className="pp_child_content">
                현대자동차㈜ / 기아㈜는 (이하 &#39;회사&#39;는)
                개인정보보호법 등 관련 법령상의 개인정보보호 규정을 준수하여
                고객의 개인정보 및 권익을 보호하고 개인정보와 관련한 고객의
                고충을 원활하게 처리할 수 있도록 다음과 같은 개인정보 처리방침을
                두고 있습니다.
              </p>
            </div>
            
            <div className='pp_child_wrap'>
              <b className="pp_child_title">2. 개인정보의 수집 및 이용목적</b>
              <p className="pp_child_content">
                회사는 수집한 개인정보를 다음의 목적을 위해 사용합니다.
              </p>
              <p className="pp_child_content">- 목적 : 제보, 민원 처리</p>
              <p className="pp_child_content">
                - 자세한 내용 : 제보에 따른 민원처리 및 결과 회신
              </p>
            </div>
            
            <div className='pp_child_wrap'>
              <b className="pp_child_title">
                3. 수집하는 개인정보의 항목 및 수집방법
              </b>
              <p className="pp_child_content">1) 수집항목</p>
              <p className="pp_child_content mt-16">(1) 개인정보 수집 항목</p>
              <p className="pp_child_content">
                제보는 익명 혹은 실명(개인정보 제공 범위 선택가능)으로
                이루어집니다. 회사는 서비스 이용 시 이용자로부터 아래와 같은
                개인정보를 수집하고 있습니다.
              </p>
              <p className="pp_child_content">- 구분 : 선택항목(실명제보 시)</p>
              <p className="pp_child_content">
                - 자세한 내용 : 성명, 생년월일, 연락처, 이메일 주소, 차량번호,
                제보 및 처리기록
              </p>
              <p className="pp_child_content">
                회사는 이용자의 사생활을 현저히 침해할 우려가 있는
                민감정보(사상ㆍ신념, 노동조합ㆍ정당의 가입ㆍ탈퇴, 정치적 견해,
                건강, 성생활 등에 관한 정보 등)는 수집하지 않습니다.
              </p>
              <p className="pp_child_content">
                회사는 원칙적으로 이용자가 만 14세 미만자일 경우 개인정보를
                수집하지 않습니다. 서비스 이용을 위하여 만 14세 미만자의
                개인정보를 부득이 수집할 때에는 사전에 법정대리인에게 동의를
                구하고, 관련 업무가 종료됨과 동시에 해당 정보를 지체 없이
                파기하며, 업무가 진행되는 동안 개인정보를 철저히 관리합니다.
              </p>
              <p className="pp_child_content mt-16">(2) 개인정보 수집 방법</p>
              <p className="pp_child_content">
                회사는 다음과 같은 방법으로 개인정보를 수집합니다.
              </p>
              <p className="pp_child_content">
                - 홈페이지, 팩스∙전화∙이메일을 통한 수집
              </p>
              <p className="pp_child_content">
                - 생성 정보 수집 툴을 통한 수집
              </p>
              <p className="pp_child_content">
                회사는 이용자가 회사의 개인정보 수집ㆍ이용 동의서의 각 내용에
                대해 &quot;동의&quot; 또는 &quot;동의하지 않음&quot;을 선택할 수
                있는 절차를 갖추고 있습니다.
              </p>
            </div>
            
            <div className='pp_child_wrap'>
              <b className="pp_child_title">4. 개인정보의 보유 및 이용기간</b>
              <p className="pp_child_content">
                1) 회사는 제보자의 개인정보를 수집한 때로부터 개인정보의 수집 및
                이용에 관한 동의 목적을 달성할 때까지 제보자의 개인정보를 보유
                및 이용하며, 개인정보 수집 및 이용목적이 달성된 경우에는 해당
                정보를 지체 없이 파기합니다.
              </p>
              <p className="pp_child_content mt-16">
                2) 단, 상법 등 관련법령의 규정에 의하여 보존할 의무가 있는 경우
                회사는 제보자의 개인정보를 보관합니다. 이 경우 회사는 보관하는
                정보를 그 보관의 목적으로만 이용하며 보존기간은 다음과 같습니다.
              </p>
              <p className="pp_child_content">
                ① 회사의 상업장부와 영업에 관한 중요서류 및 전표 등에 관련된
                정보 : 10년 - 중요서류 / 5년 - 전표(상법)
              </p>
              <p className="pp_child_content">
                ② 모든 거래에 관한 장부 및 증빙서류와 관련된 정보 : 5년
                (국세기본법, 법인세법)
              </p>
              <p className="pp_child_content">
                ③ 계약 또는 청약철회 등에 관한 기록, 대금결제 및 재화 등의
                공급에 관한 기록 : 5년 (전자상거래 등에서의 소비자보호에 관한
                법률)
              </p>
              <p className="pp_child_content">
                ④ 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래
                등에서의 소비자보호에 관한 법률)
              </p>
              <p className="pp_child_content">
                ⑤ 장부와 교부한 세금계산서 또는 영수증 : 5년 (부가가치세법)
              </p>
              <p className="pp_child_content">
                ⑥ 「통신비밀보호법」에 따른 서비스 이용 기록, 접속 로그, 접속 IP
                정보 : 3개월
              </p>
              <p className="pp_child_content">
                ※ &#39;개인정보 유효기간제&rsquo;에 따라 1년간 서비스를 이용하지
                않은 회원의 개인정보를 별도로 분리 보관하여 관리하고 있습니다.
              </p>
            </div>
            
            <div className='pp_child_wrap'>
              <b className="pp_child_title">5. 개인정보의 제3자 제공</b>
              <p className="pp_child_content">
                ① 회사는 제보자의 개인정보를 제2조(개인정보의 수집 및
                이용목적)에서 명시한 범위 내에서만 처리하며, 제보자의 동의, 법률의
                특별한 규정 등 개인정보 보호법 제17조에 해당하는 경우에만
                개인정보를 제3자에게 제공합니다.
              </p>
              <p className="pp_child_content mt-16">
                ② 회사는 부정비리 아닌 업무나 그룹사에 대한 제보가 접수되면, 해당
                그룹사에게 제보내용을 전달하여 민원을 해결하고자 합니다.
                해당그룹사는 제보에 따른 민원처리 및 결과회신을 위해 실명제보 시
                그 성명, 생년월일, 연락처, 이메일 주소를 제공하고, 해당 그룹사는
                민원처리 및 결과회신기간 동안 개인정보를 보유&middot;이용합니다.
              </p>
            </div>
            
            <div className='pp_child_wrap'>
              <b className="pp_child_title">6. 개인정보의 처리위탁</b>
              <p className="pp_child_content">
                회사는 서비스 제공을 위하여 필요한 업무 중 일부를 외부 업체에
                위탁하고 있으며, 위탁업무계약서 등을 통하여 개인정보보호 관련
                법규의 준수, 개인정보에 관한 비밀유지, 제 3 자 제공에 대한 금지,
                사고시의 책임부담, 위탁기간, 처리 종료 후의 개인정보의 반환 또는
                파기 의무 등을 규정하고, 이를 준수하도록 관리하고 있습니다.
              </p>
              <p className="pp_child_content">
                위탁 업체 리스트는 해당 서비스 변경 및 계약기간에 따라 변경될 수
                있으며 변경 시 공지사항을 통해 사전 공지합니다.
              </p>
              <p className="pp_child_content">
                - 수탁업체의 명칭 : 현대오토에버(주)
              </p>
              <p className="pp_child_content">
                - 위탁하는 업무의 내용 : 시스템 관리
              </p>
            </div>
            
            <div className='pp_child_wrap'>
              <b className="pp_child_title">7. 개인정보의 파기절차 및 방법</b>
              <p className="pp_child_content">
                회사는 다른 법률에 따라 개인정보를 보존하여야 하는 경우가 아닌
                한, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
                지체없이 파기하는 것을 원칙으로 합니다.
              </p>
              <p className="pp_child_content">
                파기절차 및 방법은 다음과 같습니다.
              </p>

              <p className="pp_child_content mt-16">1) 파기절차</p>

              <p className="pp_child_content">
                제보자가 회사에 제공한 개인 정보는 수집 &middot; 이용 목적이
                달성된 후에는 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부
                방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및
                이용기간 참조) 일정 기간 저장된 후 파기됩니다. 별도 DB로 옮겨진
                개인정보는 법률에 의한 경우가 아니고서는 보유되는 목적 이외의
                다른 목적으로 이용되지 않습니다.
              </p>

              <p className="pp_child_content mt-16">2) 파기방법</p>

              <p className="pp_child_content">
                전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적
                방법을 사용하여 삭제합니다.
              </p>

              <p className="pp_child_content">
                종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
                파기합니다.
              </p>
            </div>
            
            <div className='pp_child_wrap'>
              <b className="pp_child_title">
                8. 제보자 및 법정대리인의 권리와 그 행사방법
              </b>
              <p className="pp_child_content">
                1) 제보자 또는 법정대리인(만 14세 미만의 아동인 경우)은 회사에
                대하여 언제든지 개인정보 수집 &middot; 이용 &middot; 제공 등의
                동의를 철회(가입해지)할 수 있습니다.
              </p>
              <p className="pp_child_content mt-16">
                2) 회사에 대하여, 제보자는 자신의 개인정보를, 법정대리인은 만
                14세 미만의 아동의 개인정보를 각 열람 &middot; 제공 및 오류를
                정정하도록 요구할 수 있습니다.
              </p>
              <p className="pp_child_content mt-16">
                3) 제보자는 위와 같은 권리를 온라인에서는 회사 홈페이지에
                접속하여 본인 확인 절차를 거친 후 개인정보관리 메뉴에서
                행사가능하며, 오프라인에서는 서면, 전화 또는 이메일을 통해
                고객센터 또는 회사 개인정보관리책임자에게 연락하는 방법으로
                행사할 수 있습니다. 제1, 2항의 권리행사는 정보주체의
                법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수
                있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에
                따른 위임장을 제출하셔야 합니다.
              </p>
              <p className="pp_child_content mt-16">
                4) 제보자가 개인정보 오류정정을 요구하신 경우, 회사는 오류정정을
                완료하기 전까지 당해 개인정보를 이용 &middot; 제공하지 않으며,
                이미 제3자에게 당해 개인정보를 제공한 경우에는 제3자에게
                지체없이 통지하여 오류 정정이 이루어지도록 하고 있습니다.
              </p>
              <p className="pp_child_content mt-16">
                5) 제보자 또는 법정 대리인이 동의철회(가입해지)한 경우, 회사는
                지체없이 파기하는 것을 원칙으로 하나 관계법령에서 의무적으로
                보유하도록 한 경우에는 개인정보 처리방침 &#39;개인정보의 보유 및
                이용기간&#39;에 따라 처리하고, 반드시 필요한 경우에만 열람 또는
                이용이 가능하도록 조치하고 있습니다.
              </p>
            </div>
            
            <div className='pp_child_wrap'>
              <b className="pp_child_title">
                9. 개인정보의 안전성 확보 조치에 관한 사항
              </b>
              <p className="pp_child_content">
                ※ 개인정보의 기술적/관리적 보호 대책
              </p>
              <p className="pp_child_content">
                회사는 제보자의 개인정보를 처리함에 있어 개인정보의 분실, 도난,
                누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 아래와 같은
                기술적/관리적 대책을 적용하고 있습니다.
              </p>
              <p className="pp_child_content mt-16">1) 기술적 조치</p>
              <p className="pp_child_content">① 고객정보의 암호화</p>
              <p className="pp_child_content">
                소중한 제보자의 개인정보는 DB내에 암호화되어 저장되어 외부
                침입에 의해 유출되더라도 제보자의 개인정보를 활용할수 없도록
                하고 있습니다.
              </p>
              <p className="pp_child_content">② 통신 구간 암호화</p>
              <p className="pp_child_content">
                제보자가 홈페이지를 통한 제보 접수시, 제보자
                정보를&nbsp;입력하여 전달하는 구간에 대해 SSL을 통해 제보자의
                정보가 안전하게 전송되도록 조치하고 있습니다.
              </p>
              <p className="pp_child_content">③ 보안솔루션의 설치</p>
              <p className="pp_child_content">
                서비스 제공 및 제보자의 정보를 안전하게 관리하기 위하여
                개인정보처리시스템에 대해 백신프로그램의 설치, 주기적인 업데이트
                및 정기점검을 수행하고 있으며, DB암호화 솔루션 및 화면캡처방지
                솔루션을 적용하고 있습니다. 또한 해킹 등의 외부침입을 대비하여
                침입차단/탐지시스템을 설치하고 통합보안관재센터를 통해 해킹 및
                외부침입에 대비하여 계속적으로 모니터링을 수행하고 있습니다.
              </p>
              
              <p className="pp_child_content mt-16">2) 관리적 조치</p>
              <p className="pp_child_content">① 개인정보관리체계 수립</p>
              <p className="pp_child_content">
                개인정보를 안전하게 관리하기위하여 회사 내부적으로 개인정보
                관리체계를 수립하여 운영하고 있습니다.
              </p>
              <p className="pp_child_content">② 개인정보보호 위원회 운영</p>
              <p className="pp_child_content">
                회사의 개인정보보호를 위한 위원회를 구성하여 연2회 이상 위원회
                회의를 개최하고 개인정보관리체계의 운영 및 개인정보보호 이슈
                등의 사항에 대하여 개선하고 바로잡기 위한 노력을 기울이고
                있습니다.
              </p>
              <p className="pp_child_content">③ 개인정보 취급자 관리</p>
              <p className="pp_child_content">
                제보자의 개인정보를 처리하는 개인정보처리자를 대상으로
                개인정보보호 서약서를 제출받고, 연1회 이상의 개인정보보호 교육을
                수행하여 고객정보의 중요성과 안전하게 관리하도록 하고 있습니다.
                또한 개인정보처리자의 권한 관리를 통하여 불필요한 제보자의
                개인정보에 대한 접근과 노출을 최소화하고 있습니다.
              </p>
            </div>
            
            <div className='pp_child_wrap'>
              <b className="pp_child_title">
                10. 개인정보의 자동 수집 장치의 설치 &middot; 운영 및 그 거부에
                관한 사항
              </b>
              <p className="pp_child_content">
                회사는 제보자의 정보를 수시로 저장하고 찾아내는
                &#39;쿠키(cookie)&#39; 등을 운용합니다. 쿠키란 회사의 웹사이트를
                운영하는데 이용되는 서버가 제보자의 브라우저에 보내는 아주 작은
                텍스트 파일로서 고객의 컴퓨터 하드디스크에 저장됩니다. 회사는
                다음과 같은 목적을 위해 쿠키를 사용합니다.
              </p>
              <p className="pp_child_content mt-16">1) 쿠키 등 사용 목적</p>
              <p className="pp_child_content">
                홈페이지 접속빈도 및 방문시간 분석, 제보자의 관심분야 파악 및
                분석, 각종 이벤트 참여 및 방문횟수 파악 등을 통한 타겟 마케팅 및
                개인 맞춤 서비스 제공을 위해 쿠키 등을 사용합니다. 제보자는 쿠키
                설치에 대한 선택권을 가지고 있습니다. 따라서, 제보자는
                웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나,
                쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을
                거부할 수도 있습니다.
              </p>
              
              <p className="pp_child_content mt-16">2) 쿠키 설정 거부 방법</p>
              <p className="pp_child_content">
                쿠키 설정을 거부하는 방법으로는 제보자가 사용하는 웹 브라우저의
                옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다
                확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
              </p>

              <p className="pp_child_content">
                다만, 쿠키의 저장을 거부할 경우에는 일부 서비스는 이용에
                어려움이 있을 수 있습니다.
              </p>

              <p className="pp_child_content">
                ※ 설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의도구
                &gt; 인터넷옵션 &gt; 개인정보 &gt; 고급 &gt; 설정방법 선택
              </p>
            </div>
            
            <div className='pp_child_wrap'>
              <b className="pp_child_title">
                11. 개인정보 보호책임자 및 개인정보관련 불만처리에 관한 사항
              </b>
              <p className="pp_child_content">
                1) 회사는 제보자의 개인정보를 보호하고, 개인정보와 관련한 불만을
                처리하기 위하여 아래와 같이 담당 부서 및 개인정보 보호책임자를
                지정하고 있습니다.
              </p>
              <p className="pp_child_content">■ 현대자동차</p>
              <p className="pp_child_content">- 개인정보 보호책임자</p>
              <p className="pp_child_content">- 성명 : 이충연</p>
              <p className="pp_child_content">- 직책: 책임매니저</p>
              <p className="pp_child_content">- 연락처 : 02-3464-3500</p>
              <p className="pp_child_content">■ 기아</p>
              <p className="pp_child_content">- 개인정보 보호책임자</p>
              <p className="pp_child_content">- 성명 : 박상철</p>
              <p className="pp_child_content">- 직책 : 책임매니저</p>
              <p className="pp_child_content">- 연락처 : 02-3464-3500</p>
              
              <p className="pp_child_content mt-16">
                2) 제보자가 위 고객서비스 담당부서 또는 개인정보 보호책임자에게
                회사의 개인정보 처리 사항에 관한 의견 또는 불만을 제기하실 경우,
                회사는 신속하고 성실하게 조치하여 문제가 해결될 수 있도록
                노력하겠습니다.
              </p>
              
              <p className="pp_child_content mt-16">
                3) 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래
                기관에 문의하시기 바랍니다.
              </p>
              <p className="pp_child_content">
                개인정보 침해신고센터(한국인터넷진흥원 운영) :
                privacy.kisa.or.kr / (국번없이)118
              </p>
              <p className="pp_child_content">
                대검찰청 인터넷범죄수사센터 : www.spo.go.kr / 02-3480-3573
              </p>

              <p className="pp_child_content">
                경찰청 사이버테러대응센터 : www.netan.go.kr / 1566-0112
              </p>
            </div>
            
            <div className='pp_child_wrap'>
              <b className="pp_child_title">
                12. 개인정보 처리 방침의 변경에 관한 사항
              </b>
              <p className="pp_child_content">
                회사는 본 개인정보처리방침을 변경하는 경우에는 그 이유 및
                변경내용을 인터넷 홈페이지 첫 화면의 공지사항란 또는 별도의 창을
                통하는 등의 방법으로 최소 7일 이전에 공지할 것입니다.
              </p>

              <p className="pp_child_content">
                본 방침은 2020년 5월 1일 부터 시행됩니다.
              </p>
            </div>
          </div>
        );
      } else {
        return (
          <div className="privacy_content">
            <p>
              Hyundai Motor Company / Kia Motors Corporation (hereinafter,
              &ldquo;the Company&rdquo;) places a high value on personal
              information of data subjects (hereinafter, &ldquo;user&rdquo;,
              &ldquo;users&rdquo;, &ldquo;you&rdquo;, &ldquo;your&rdquo;) who
              use and make reports on the Company&rsquo;s Ethical Management
              website(system). The Company makes every effort to comply with
              applicable laws and regulations related to the protection of
              personal information. This Privacy Policy aims to protect
              users&rsquo; rights to the protection of personal information and
              to effectively deal with matters with regard to the processing of
              personal information.
            </p>
            <div className='pp_child_wrap'>
              <b className="pp_child_title">
                1. Purpose for Collection and Use of the Personal Information
              </b>
              <p className="pp_child_content">
                The Company collects and uses personal information of users for
                the purposes described below. The collected personal information
                will be used only for the purposes below, and when the Company
                wishes to change or add the purposes, it will take necessary
                measures to comply with relevant laws and regulations.
              </p>
              <p className="pp_child_content">
                - Reports/Complaints Processing (receiving and investigating the
                reports, notifying the result etc.) related to the
                Company&rsquo;s corruption, irregularity and others
              </p>
            </div>
            <div className='pp_child_wrap'>
              <b className="pp_child_title">
                2. Personal Information to be Collected and Used
              </b>
              <p className="pp_child_content">
                You can choose to report either anonymously or with your name on
                it. If you report with your name, the following personal
                information will be collected.
              </p>
              <p className="pp_child_content">
                - First and last name, date and year of birth, e-mail address,
                contact(telephone number)&rsquo;
              </p>
            </div>
            <div className='pp_child_wrap'>
              <b className="pp_child_title">
                3. Retention of Personal Information and Period of Use
              </b>
              <p className="pp_child_content">
                Personal information collected via Ethics Management system will
                be destroyed without delay after the purpose of use is achieved.
              </p>
              <p className="pp_child_content">
                However, exceptions apply to cases in need of additional
                retention, for example, for dispute resolution or mandatory
                retention according to applicable laws or regulations
              </p>
            </div>
            <div className='pp_child_wrap'>
              <b className="pp_child_title">
                4. Destruction of Personal Information
              </b>
              <p className="pp_child_content">
                ① Unless the Company is obligated to preserve personal
                information under relevant regulations, the Company destroys
                personal information when it becomes unnecessary, without undue
                delay (i.e. when the retention period is terminated, when the
                purpose of collection and use is achieved etc.)
              </p>
              <p className="pp_child_content mt-16">
                ② The procedure and methods of destruction are as follows.
              </p>
              <p className="pp_child_content">A. Procedure of destruction</p>
              <p className="pp_child_content">
                The Company selects personal information to be destroyed and
                destroys it under the approval of the Company&rsquo;s Personal
                Information manager.
              </p>
              <p className="pp_child_content">B. Methods of destruction</p>
              <p className="pp_child_content">
                - Personal information recorded or stored in electronic format
                will be destroyed in non-restorable manner.
              </p>
              <p className="pp_child_content">
                - Personal information recorded or stored in printed (paper)
                format will be shredded or incinerated.
              </p>
            </div>
            <div className='pp_child_wrap'>
              <b className="pp_child_title">
                5. Disclosure of Personal Information to 3rd Parties
              </b>
              <p className="pp_child_content">
                ① 3rd Parties (possibly) receiving Personal Information: When the
                reporting/complaint is about other group companies of the Company,
                the Company will disclose your personal information to the
                applicable group company such as Hyundai Mobis, Hyundai Glovis,
                Hyundai Engineering, Hyundai E&amp;C, overseas affiliates etc.
              </p>
              <p className="pp_child_content mt-16">
                ② Purpose of Disclosure: Reports/Complaints Processing (receiving
                and investigating the reports, notifying the result etc.) related
                to the involved company&rsquo;s corruption, irregularity and
                others
              </p>
              <p className="pp_child_content mt-16">
                ③ Categories of Personal Information: First and last name, Date
                and year of birth, Email address, Contact (Phone number)
              </p>
              <p className="pp_child_content mt-16">
                ④ Period of RetentionㆍUse: Until the purpose of use is achieved
              </p>
            </div>
            <div className='pp_child_wrap'>
              <b className="pp_child_title">
                6. Consignment of personal information processing
              </b>
              <p className="pp_child_content">
                ① The Company assigns the processing of personal information to an
                external specialized company.
              </p>
              <p className="pp_child_content">
                - Assignee: Hyundai Autoever Co., Ltd.
              </p>
              <p className="pp_child_content">
                - The scope of Assigned work: &lsquo;Ethical Management
                system&rsquo; Management
              </p>
              <p className="pp_child_content mt-16">
                ② When the Company enters into the consignment contract with the
                Assignee, the following contents will be specified: prohibition of
                processing personal information outside the assigned purpose,
                technical and managerial protection measures, limitation of
                re-consignment, management and supervision on the assignee,
                remedies etc. The Company oversees whether the assignee collects
                and uses personal information in safe and appropriate manner.
              </p>

              <p className="pp_child_content mt-16">
                ③ When the scope of assigned work or the assignee is changed, the
                Company will reflect such change to this Privacy Policy and make
                it public on Ethical Management website.
              </p>
            </div>
            <div className='pp_child_wrap'>
              <b className="pp_child_title">
                7. Location of processing and transfer of personal information
                to other countries
              </b>
              <p className="pp_child_content">
                ① In principle, your personal information will be processed in
                Republic of Korea.
              </p>

              <p className="pp_child_content mt-16">
                ② In case of the cases involving overseas affiliates, your
                personal information will be transferred to the 3rd country of
                the location of the affiliate.
              </p>
            </div>
            <div className='pp_child_wrap'>
              <b className="pp_child_title">
                8. Safeguarding measures of Personal Information
              </b>
              <p className="pp_child_content">
                The Company takes measures to safeguard personal information as
                follows.
              </p>
              <p className="pp_child_content">① Technical measures</p>
              <p className="pp_child_content">
                A. Encryption of user information
              </p>
              <p className="pp_child_content">
                Personal information of users is stored in the DB in encrypted
                from, so that personal information cannot be used in case of
                accidental leakage.
              </p>
              <p className="pp_child_content">
                B. Encryption of communications section
              </p>
              <p className="pp_child_content">
                For the section where the users&rsquo; personal information is
                entered, personal information is securely delivered through SSL.
              </p>
              <p className="pp_child_content">
                C. Installation of security solution
              </p>
              <p className="pp_child_content">
                For providing the service and safeguarding personal information,
                the Company has the following security measures in personal
                information processing system: vaccine program, periodical
                updates and program checks. For fighting intrusions such as
                hacking, continuous monitoring is performed by installing the
                intrusion prevention and detection system.
              </p>
              <p className="pp_child_content mt-16">② Managerial measures</p>
              <p className="pp_child_content">
                A. Establishment of personal information management system
              </p>
              <p className="pp_child_content">
                For safeguarding personal information, an internal management
                system for personal information is established.
              </p>
              <p className="pp_child_content">
                B. Operation of personal information protection committee
              </p>
              <p className="pp_child_content">
                The Company organizes a committee for personal information
                protection and holds one or more meetings a year to improve and
                correct issues relating to the operation of the personal
                information management system and the protection of personal
                information.
              </p>
              <p className="pp_child_content">
                C. Management of personal information processors
              </p>
              <p className="pp_child_content">
                For personal information processors who handle user information,
                the Company conducts at least 1 personal information protection
                training session a year, to emphasize the importance of
                information processing and to ensure that personal information
                is handled securely. Further, the Company controls access rights
                of personal information processors to minimize unnecessary
                access to and disclosure of personal information.
              </p>
            </div>
            <div className='pp_child_wrap'>
              <b className="pp_child_title">
                9. Rights of Users and the Exercise thereof
              </b>
              <p className="pp_child_content">
                ① Users may exercise the following rights to the Company, with
                regards to the protection of their personal information.
              </p>
              <p className="pp_child_content">A. Right of access</p>
              <p className="pp_child_content">B. Right to rectification</p>
              <p className="pp_child_content">C. Right to erasure</p>
              <p className="pp_child_content">
                D. Right to restriction of processing
              </p>
              <p className="pp_child_content mt-16">
                ② Users may contact our Personal Information Manager at any time
                to exercise the aforementioned rights, in paper or electronic
                format such as email, fax etc. In this case, the Company will
                take necessary measures without undue delay.
              </p>
              <p className="pp_child_content mt-16">
                ③ When users request the rectification or erasure of their
                personal information, such information will not be used or
                provided to third parties until the necessary measure is taken.
              </p>
            </div>
            <div className='pp_child_wrap'>
              <b className="pp_child_title">
                10. Automatic collection tool for cookies and the denial thereof
              </b>
              <p className="pp_child_content">
                The company uses &lsquo;cookies&rsquo; in the website.
              </p>
              <p className="pp_child_content">
                - A cookie is a small piece of text sent to your browser by a
                website you visit. It helps the website to remember information
                about your visit.
              </p>
              <p className="pp_child_content">
                You can configure your browser settings to either allow all
                cookies, indicate and confirm when the Company has set a cookie
                in your browser, or block all cookies.
              </p>
              <p className="pp_child_content">
                ※ How to configure (in case of Internet explorer): Tool &gt;
                Internet Option &gt; Personal Information &gt; Advanced settings
              </p>
            </div>
            <div className='pp_child_wrap'>
              <b className="pp_child_title">11. Personal Information Manager</b>
              <p className="pp_child_content">
                ① To protect personal information and to handle complaints with
                regards to personal information, the Company has appointed
                Personal Information Manager as below.
              </p>
              <p className="pp_child_content">◈ Hyundai Motor Company</p>
              <p className="pp_child_content">◈ Personal Information Manager</p>
              <p className="pp_child_content">
                - Name of Department : Audit Planning Team
              </p>
              <p className="pp_child_content">- Name : ChungYeon Lee</p>
              <p className="pp_child_content">- Position : Senior Manager</p>
              <p className="pp_child_content">
                - e-mail : auditlee@hyundai.com
              </p>
              
              <p className="pp_child_content">◈ Kia Motors Corporation</p>
              <p className="pp_child_content">◈ Personal Information Manager</p>
              <p className="pp_child_content">
                - Name of Department : Audit Planning Team
              </p>
              <p className="pp_child_content">- Name : SangCheol Park</p>
              <p className="pp_child_content">- Position : Senior Manager</p>
              <p className="pp_child_content">- e-mail : now4future@kia.com</p>

              <p className="pp_child_content mt-16">
                ② Should users have any inquiries about the protection of their
                personal information, the handling of complaints, or the remedy
                for damages while using the services provided by the Company,
                users may contact our Personal Information Manager. In this
                case, the Company will answer to the inquiries promptly and
                sincerely.
              </p>
            </div>
          </div>
        );
      }
    }
  };

  render() {
    let {
      listPublicDate,
    } = this.state;
    let { translate } = this.props;
    return (
      <LayoutPrivacy>
        <div className="pri-poli-container">
          <BannerContent
            title={translate('privacy.titleBanner')}
            content=""
            background="/Images/policy-banner.png"
          />
          <div className="pp-body">
            <div className="pp-nav">
              <div className="pp-nav-content">
                <BreadCrumb
                  firstUrl={translate('privacy.firstUrl')}
                  firstRouter="/privacy-policy"
                />
                {/* <Breadcrumb separator=">">
                  <Breadcrumb.Item><img className="home-icon" src="/Images/ichome48px.svg" alt="" /></Breadcrumb.Item>
                  <Breadcrumb.Item href=""><span>{translate('privacy.firstUrl')}</span></Breadcrumb.Item>
                </Breadcrumb> */}
              </div>
            </div>
            <div className="pp-content-ct">
              <div className="pp-content">
                <div className="pp-datepicker">
                  <Dropdown id="policy-dropdown" data={listPublicDate.map(({id,publicDate}) => ({id, value:publicDate}))} onClick={this.onChange} />
                </div>
                {this.renderContent()}
              </div>
            </div>
          </div>
        </div>
      </LayoutPrivacy>
    );
  }
}

export default withLocalize(PrivacyPolicy);
