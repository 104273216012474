import React, { Component } from 'react';
import LayoutEthical from '../../../hocs/LayoutEthical';
import { Link } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { Helmet } from 'react-helmet';
import Loading from '../../../components/Loading';
import BreadCrumb from '../../../components/BreadCrumb';
import { apiListContentProgress } from '../../../services/api/index';
import { HYUNDAI_SITE_ID } from '../../../constants/index';
import HorizontalTimeline1 from './HorizontalTimeline';
import './style.scss';

let tabIndex = 1;
class ProgressEthical extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      previous: 0,
      translateX: 0,
      loading: false,
      lstContentProgress: [],
    };
  }
  componentWillMount(){
    if (this.props.activeLanguage.code === 'en') {
      this.props.history.push('/');
    }
  }
  componentDidMount() {    
    this.loadAPI(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activeLanguage.code !== nextProps.activeLanguage.code) {
      this.loadAPI(nextProps);
    }
  }

  loadAPI = async props => {
    let rs = await apiListContentProgress(props.activeLanguage.code);

    if (rs.data && rs.data.data) {
      this.setState({
        lstContentProgress: rs.data.data.contentProgressList,
        loading: false,
        value: 0,
        previous: 0
      });
    } else {
      this.setState({
        // loading: true
      });
    }
  }

  render() {
    const { translate } = this.props;
    const { lstContentProgress, loading } = this.state;
    return (
      <LayoutEthical>
        <div className="container" id='contentProgress'>
          <div className="EthicalManagement_Progress">
            <div className="breadcumb">
            <Helmet>
              <title>{translate('helmet.progress')}</title>
            </Helmet>
              <BreadCrumb
                firstUrl={translate('progress.firstUrl')}
                firstRouter='/progress'
                secondUrl={translate('progress.secondUrl')}
                secondRouter='/progress'
              />
            </div>
            <div className="ew-post ">
              <div className="ew-post-detail">
                <h1 className="ew-post-title">{translate('title.progress')}</h1>
                <p className="ew-post-subject">{translate('title.quote')}</p>
              </div>
              <div className="ew-post-content">
                <HorizontalTimeline1 data={this.state.value} histories={lstContentProgress}/>
              </div>
            </div>
          </div>
        </div>
      </LayoutEthical>
    );
  }
}

export default withLocalize(ProgressEthical);
