/**
 * ant 모달 컴포넌트 접근성 관련 수정해주는 함수
 * - id 혹은 element 중 하나를 객체에 담아 전달
 */
export const focusReturnModalClose = ({id, el}) => {
  const triggerButton = id ? document.getElementById(id) : el;
  if(!triggerButton) {
    console.error('ERROR: focusReturnModalClose : modal trigger button을 찾을 수 없습니다. id 값이나 trigger button element를 올바르게 전달했는지 확인해주세요.');
    return;
  }
  setTimeout(() => {
    const modals = document.querySelectorAll('.ant-modal-wrap');
    const crrModal = modals[modals.length - 1];
    // modal 오픈시 modal 영역에 focus 가도록 설정
    // 간혹 스크롤이 존재하는 modal이 있어 modal body영역에 focus될 경우 어색하게 세로 중앙 정렬 되는 이슈가 있어 wrap에 focus
    crrModal.setAttribute('tabindex',0);
    setTimeout(() => {
      crrModal.focus();
    }, 50);
    // ant에서 불필요하게 렌더링 하는 div - aria-hidden 속성 제거 및 focus 가지 않도록 설정
    const hiddenDivs = crrModal.querySelectorAll('div[aria-hidden=true]');
    hiddenDivs.forEach(hiddenDiv => {
      hiddenDiv.removeAttribute('aria-hidden');
      hiddenDiv.setAttribute('tabindex',-1);
    });
    // 종료 후 trigger 버튼으로 포커스 복귀
    const antModalClose =  document.querySelector('.ant-modal-close');
    const antModalMask =   document.querySelector('.ant-modal-mask');
    antModalClose.onclick = () => triggerButton.focus();
    antModalMask.onclick = () => triggerButton.focus();  
  }, 100);
};