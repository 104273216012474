import React, { useState, useEffect } from 'react';
import BannerContent from '../../components/BannerContent';
import BreadCrumb from '../../components/BreadCrumb';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import styled from 'styled-components';
import { withLocalize } from 'react-localize-redux';
import './style.scss';

const Flex = styled.div`
  display: flex;
`;

const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  document.getElementById('backtotopsitemap').blur();
};
const handleKeyPress=(event) =>{
  if(event.key === 'Enter'){      
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};
const SitemapLayout = ({
  languages,
  activeLanguage,
  translate,
  setActiveLanguage,
  ...props
}) => {
  const { children } = props;
  let [overFlow, setOverFlow] = useState(null);

  useEffect(()=> {
    // if(localStorage.getItem('languageCode') === 'en'){
    //   document.getElementsByClassName('skip-link')[0].innerHTML = 'Skip to main';
    // }
  },[]);

  return (
    <>
    <a className="skip-link" href='#contentSitemap' tabIndex='0'>{translate('webAccessibility.skipLink')}</a>
    <div className={`LayoutSitemap_wrap ${overFlow}`}>
      <div className="lsw_header_wrap">
        <Header setOverFlow={setOverFlow} type={translate('siteMap.titleBanner')}/>
      </div>
      <div id='contentSitemap'>
        <BannerContent
          title={translate('siteMap.titleBanner')}
          content=""
          background="/Images/bgr_sitemap.png"
        />
        <div className="sitemap_content">
          <div className="rrh_bread">
            <BreadCrumb firstUrl={translate('siteMap.firstUrl')} firstRouter='/site-map' />
          </div>
          {children}
          <Flex className="backtotop_btn">
          <a href='javascript: void(0)' tabIndex="0" id='backtotopsitemap' onClick={scrollTop} onKeyPress={handleKeyPress}>
            <img
              src="/Images/icarrowupward48px.svg"
              alt={translate('TooltipHome.btnToTop')}
              title={translate('TooltipHome.btnToTop')}
            />
            </a>
          </Flex>
        </div>
      </div>
      <Footer />
    </div>
    </>
  );
};

export default withLocalize(SitemapLayout);
