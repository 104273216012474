import React, { Component, useState } from 'react';
import { Modal } from 'antd';
import { withLocalize } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Layout from '../../hocs/Layout';
import Banner from '../../components/Banner';
import styles from './style.module.scss';
import './style.scss';

const Flex = styled.div`
  display: flex;
`;
const Wrap1200 = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;
const Highlight = styled.span`
  font-family: HDharmonyB;
  color: #9f7635;
`;

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      width: null
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }
  resize() {
    this.setState({ width: window.innerWidth <= 768 });
  }
  scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    document.getElementById('backtotop_btn').blur();
  }
  handleKeyPress=(event) =>{
    if(event.key === 'Enter'){      
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  render() {
    const { translate, activeLanguage } = this.props;
    const { code } = activeLanguage;
    
    return (
      <Layout>
        <Helmet>
          <title>{translate('helmet.homepage')}</title>
        </Helmet>
        <div className={styles.HomePage} id="#home">
          <Banner />
          <div className={styles.office}>
            <h1 className={styles.o_title}>{translate('home.report')}</h1>
            <span className={styles.o_ContentTitle}>
              {translate('home.operator')}
            </span>
          </div>

          {/* office style */}
          <Flex className={styles.office_img_wrap}>
            <div className={styles.content}>
              <Flex className={styles.wrap}>
                <Flex className={styles.wrap_content}>
                  <span className={styles.oiw_title}>
                    {translate('home.cyberAuditOffice')}
                  </span>
                  <p className={`${styles.oiw_content} ${styles.oiw_margin}`}>
                    {translate('home.auditOffice')}
                  </p>
                  <Link className={styles.btn_detail} to="/report/guideline" title={translate('webAccessibility.homeGotoReport')}>
                    {translate('btn.button-report')}
                  </Link>
                </Flex>
              </Flex>
            </div>
            <div
              className={styles.img}
              alt={translate('TooltipHome.image')}
              title={translate('TooltipHome.image')}
            />
          </Flex>

          {/* List icon */}
          <Flex
            className={`${styles.contact_wrap} ${
              code === 'en' ? 'contact_wrap_en' : null
            }`}
          >
            <Flex className={`${styles.cr_item} ${styles.cr_item1}`}>
              <Link
                to="/report/create"
                title={translate('TooltipHome.icon1')}
                // alt={translate('TooltipHome.icon1Alt')}
              ><img style={{display: 'none'}} src='' alt='' title='' /></Link>
              <div className={styles.cw_content}>
                <div className={styles.cwc_title}>
                  {translate('home.internet')}
                </div>
                <div className={styles.cwc_content}>  
                  <a className={styles.cwcc_des} href="/report/create" title={translate('TooltipHome.icon1')} alt={translate('TooltipHome.icon1Alt')}>
                    {translate('home.internetdescription')}
                  </a>
                </div>
              </div>
            </Flex>
            {code === 'kr' ? (
              <>
                {' '}
                <Flex className={`${styles.cr_item} ${styles.cr_item2}`}>
                  <div className={`${styles.cr_item_img} ${styles.cr_item2_img}`} 
                  // alt={translate('TooltipHome.icon2')} 
                  title={translate('TooltipHome.icon2')}>
                    <img style={{display: 'none'}} src='' alt={translate('TooltipHome.icon2')} title={translate('TooltipHome.icon2')} />
                  </div>
                  <div className={styles.cw_content}>
                    <div className={styles.cwc_title}>
                      {translate('home.phone')}
                    </div>
                    <a className={window.innerWidth <= 768 ? `${styles.cwc_content}` : `${styles.cwc_content} ${styles.cwc_content_mobile}`} href={window.innerWidth <= 768 ? 'tel:02-3464-3500' : null}>
                      02-3464-3500
                    </a>
                  </div>
                </Flex>
                <Flex className={`${styles.cr_item} ${styles.cr_item3}`}>
                  <div className={`${styles.cr_item_img} ${styles.cr_item3_img}`} 
                  // alt={translate('TooltipHome.icon3')} 
                  title={translate('TooltipHome.icon3')}>
                    <img style={{display: 'none'}} src='' alt={translate('TooltipHome.icon3')} title={translate('TooltipHome.icon3')} />
                  </div>
                  <div className={styles.cw_content}>
                    <div className={styles.cwc_title}>
                      {translate('home.fax')}
                    </div>
                    <div className={styles.cwc_content}>02-3464-8813</div>
                  </div>
                </Flex>
              </>
            ) : null}

            <Flex
              className={`${styles.cr_item} ${styles.cr_item4} ${
                code === 'en' ? 'cr_item4_en' : null
              }`}
            >
              <Link
                className={`${styles.cr_item_img} ${styles.cr_item4_img}`}
                // alt={translate('TooltipHome.icon4')}
                title={translate('TooltipHome.icon4')}
                onClick={() => {
                  this.setState({ visible: true });
                }}
              >
                <img style={{display: 'none'}} src='' alt={translate('TooltipHome.icon4')} title={translate('TooltipHome.icon4')} />
              </Link>
              <div className={styles.cw_content}>
                <div className={styles.cwc_title}>
                  {translate('home.postMail')}
                </div>
                <div className={`${styles.cwc_content} ${styles.cwc_space}`}>
                  {translate('home.postMailDescription')}
                </div>
              </div>
            </Flex>
          </Flex>
          <Modal
            className="someModal"
            maskClosable={false}
            visible={this.state.visible}
            footer={null}
            centered
            width="450px"
            onCancel={e => {
              e.stopPropagation();
              this.setState({ visible: false });
            }}
            closeIcon={<img src="/Images/icclose36px.svg" alt={translate('webAccessibility.closeButton')} />}
          >
            <div className="title">{translate('global.address-title')}</div>
            <div className="content">{translate('global.address-content')}</div>
          </Modal>
          {code === 'kr' ? (
            <Flex className={styles.cr_contentWrap}>
              <div className={styles.contentLeft}>
                <div className={styles.left_img}>
                  <img src="/Images/icwarning.svg" alt="" />
                </div>
                <div className={styles.left_descr}>
                  {translate('home.response')}
                </div>
              </div>
              <div className={styles.contentRight}>
                <div className={styles.cr_rightItem}>
                  <img
                    src="/Images/kia_logo.svg"
                    alt={translate('TooltipHome.iconHuyndai')}
                    title={translate('TooltipHome.iconHuyndai')}
                  />
                  <span>
                    <span className={this.state.width ? null : `${styles.crr_mobile}`} href={this.state.width ? 'tel:080-600-6000' : null}>080-600-6000</span>
                  </span>
                  <div className={styles.crr_text}>
                    <a
                      href="https://www.hyundai.com/kr/ko/ask"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {translate('home.customerCenter')}
                    </a>
                  </div>
                </div>
                <div className={styles.cr_rightItem}>
                  <img
                    src="/Images/kia_black.svg"
                    alt={translate('TooltipHome.iconKia')}
                    title={translate('TooltipHome.iconKia')}
                  />
                  <span>
                    <span className={this.state.width ? null : `${styles.crr_mobile}`} href={this.state.width ? 'tel:080-200-2000' : null}>080-200-2000</span>
                  </span>
                  <div className={styles.crr_text}>
                    <a
                      href={
                        code === 'kr'
                          ? 'https://www.kia.com/kr/customer-service/default.html'
                          : 'https://www.hyundaimotorgroup.com/CSR/Overview/CSR-System.hub'
                      }
                      target="_blank"
                    >
                      {translate('home.kiacustomerCenter')}
                    </a>
                  </div>
                </div>
              </div>
            </Flex>
          ) : null}

          <Wrap1200 className={styles.option_wrap}>
            <h1 className={styles.o_title}>
              {translate('home.titleLink')}
            </h1>
            <Flex className={`${styles.item} ${styles.item_img1}`}>
              <Flex className={styles.item_left}>
                <span className={styles.titleText}>
                  {code === 'kr'
                    ? translate('home.ethical')
                    : translate('home.Ethicscharter')}
                </span>
                <div className={styles.description}>
                  {code === 'kr'
                    ? translate('home.ethicalDetail1')
                    : translate('home.ethicalDetail2')}
                </div>
                <div
                  className={styles.link_icon_wrap}
                  alt={translate('TooltipHome.btn1')}
                  title={translate('TooltipHome.btn1')}
                >
                  <a
                    title={code === 'kr' ? translate('webAccessibility.goProgress'): null}
                    className={`${styles.button_Text} ${
                      code === 'en' ? styles.active_Button : null
                    }`}
                    href={
                      code === 'kr'
                        ? '/progress'
                        : (`${window.location.origin}/ethics-standard`)
                    }
                  >
                    {translate('btn.go')}
                  </a>
                </div>
              </Flex>
              <div className={styles.item_right}>
                <div className={styles.item_right_img} title={translate('TooltipHome.image1')}>
                  <img style={{display: 'none'}} src='' alt={translate('TooltipHome.image1')} title={translate('TooltipHome.image1')} />
                </div>
              </div>
            </Flex>
            <Flex className={`${styles.item} ${styles.item_img2}`}>
              <Flex className={styles.item_left}>
                <span className={styles.titleText}>
                  {translate('home.ebook')}
                </span>
                <div className={styles.description}>
                  <span className={styles.des_dis}>
                    {translate('home.ebookDetail')}
                  </span>
                </div>
                <div
                  className={styles.link_icon_wrap}
                  title={translate('TooltipHome.btn2')}
                >
                  <a
                    title={code === 'kr' ? translate('webAccessibility.downloadEbook'): null}
                    className={`${styles.button_Text} ${
                      code == 'en' ? styles.active_Button : null
                    }`}
                    href={
                      code === 'kr'
                        ? '/Ebook/Ebook_Kor.pdf'
                        : '/Ebook/Ebook_Eng.pdf'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {translate('btn.go')}
                  </a>
                </div>
              </Flex>
              <div className={styles.item_right}>
                <div className={styles.item_right_img} title={translate('TooltipHome.image2')}>
                  <img style={{display: 'none'}} src='' alt={translate('TooltipHome.image2')} title={translate('TooltipHome.image2')} />
                </div>
              </div>
            </Flex>
            <Flex className={`${styles.item} ${styles.item_img3}`}>
              <Flex className={styles.item_left}>
                <span className={styles.titleText}>
                  {translate('home.hyundaiMotor')}
                </span>
                <div className={styles.description}></div>
                <div className={`${styles.detail} ${styles.detail3}`}>
                  {translate('home.hyundaiMotorDetail')}
                </div>
                <div
                  className={styles.link_icon_wrap}
                  title={translate('TooltipHome.btn3')}
                >
                  <a
                    className={`${styles.button_Text} ${
                      code === 'en' ? styles.active_Button : null
                    }`}
                    title={code === 'kr' ? translate('webAccessibility.linktoHyundai'): null}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      code === 'kr'
                        ? 'https://www.hyundai.co.kr/'
                        : 'https://www.hyundaimotorgroup.com/Index.hub'
                    }
                  >
                    {translate('btn.go')}
                  </a>
                  {/* <img className={styles.link_icon} src="" alt="" /> */}
                </div>
              </Flex>
              <div className={styles.item_right}>
                <div className={styles.item_right_img} title={translate('TooltipHome.image3')}>
                  <img style={{display: 'none'}} src='' alt={translate('TooltipHome.image3')} title={translate('TooltipHome.image3')} />
                </div>
              </div>
            </Flex>
            <Flex className={`${styles.item} ${styles.item_img4}`}>
              <Flex className={styles.item_left}>
                <span className={styles.titleText}>
                  {translate('home.CSR')}
                </span>
                <div className={styles.description}></div>
                <div className={`${styles.detail} ${styles.detail4}`}>
                  {translate('home.CSRDetail')}
                </div>
                <div
                  className={styles.link_icon_wrap}
                  title={translate('TooltipHome.btn4')}
                >
                  <a
                    className={`${styles.button_Text} ${
                      code === 'en' ? styles.active_Button : null
                    }`}
                    title={code === 'kr' ? translate('webAccessibility.linktoHyundai'): null}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      code === 'kr'
                        ? 'https://www.hyundai.co.kr/CSR/Overview/CSR-System.hub'
                        : 'https://www.hyundaimotorgroup.com/CSR/Overview/CSR-System.hub'
                    }
                  >
                    {translate('btn.go')}
                  </a>
                  {/* <img className={styles.link_icon} src="" alt="" /> */}
                </div>
              </Flex>
              <div className={styles.item_right}>
                <div className={styles.item_right_img} title={translate('TooltipHome.image4')}>
                  <img style={{display: 'none'}} src='' alt={translate('TooltipHome.image4')} title={translate('TooltipHome.image4')} />
                </div>
              </div>
            </Flex>
          </Wrap1200>

          <Flex className={styles.backtotop_btn}>
            <a href="javascript: void(0);" onClick={this.scrollTop}
              onKeyPress={this.handleKeyPress} tabIndex="0" id="backtotop_btn">
              <img
                src="/Images/icarrowupward48px.svg"
                alt={translate('TooltipHome.btnToTop')}
                title={translate('TooltipHome.btnToTop')}
              />
            </a>
          </Flex>
        </div>
      </Layout>
    );
  }
}

export default withLocalize(HomePage);
