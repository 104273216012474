import React, { useEffect, useRef, useState } from 'react';
import './style.scss';

export default ({
  data = [], // {id: number, value: string}[]
  id = 'dropdownButton',
  defaultMenu = null,
  colorMode = 'black',  // 'white' | 'black
  menuOpenDir = 'bottom', // 'bottom | 'top'
  onClick,
  className
}) => {
  const dropdownTriggerRef = useRef(null);
  const dropdownListRef = useRef(null);
  let [selectedValue, setSelectedValue] = useState(defaultMenu || data[0].value);
  let [isOpen, setIsOpen] = useState(false);

  const onToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const onClickMenu = (value) => {
    setIsOpen(false);
    setSelectedValue(value);
    onClick(value);
  };

  const handleClickOutside = (e) => {
    if(!dropdownTriggerRef.current || !dropdownListRef.current) return;
    if (!dropdownTriggerRef.current.contains(e.target) && !dropdownListRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.addEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if(isOpen) {
      if(!dropdownListRef.current) return;
      dropdownListRef.current.querySelector('button').focus();
    }
  }, [isOpen]);

  return (
    <div className={`dropdown ${colorMode} menu-open-${menuOpenDir} ${className||''}`}>
      <button
        ref={dropdownTriggerRef}
        id={id}
        className='dropdown-toggle'
        onClick={onToggleDropdown}
        aria-controls='dropdown'
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        {selectedValue}
        <img
          src="/Images/arrow.png"
          alt=""
          className="ant-select-arrow-icon"
        />
      </button>
     {isOpen && <ul
        ref={dropdownListRef}
        className='dropdown-list'
        aria-labelledby={id}
      >
        {data.map(({id, value}) => (
          <li key={id} className='dropdown-list-item'>
            <button
              onClick={() => onClickMenu(value)}
              aria-selected={value === selectedValue}
            >
              {value}              
            </button>
          </li>
        ))}
      </ul>}
    </div>
  );
};
