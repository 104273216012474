import React, {useState, useEffect} from 'react';
import { ToastContainer } from 'react-toastify';
import { withLocalize } from 'react-localize-redux';
import { Link } from 'react-router-dom';
// import Header from '../../components/Header';
// import Footer from '../../components/Footer';
import Header from '../../components/Header/HeaderRealName';
import Footer from '../../components/Footer/FooterRealName';
import 'react-toastify/dist/ReactToastify.css';
import './style.scss';

const LayoutReport = ({ translate, activeLanguage, ...props }) => {
  const { children } = props;
  let [overFlow, setOverFlow] = useState(null);

  useEffect(()=> {
    // if(localStorage.getItem('languageCode') === 'en'){
    //   document.getElementsByClassName('skip-link')[0].innerHTML = 'Skip to main';
    // }
  },[]);

  const CloseButton = ({ closeToast }) => (
    <i className="btn-close-Toast" onClick={closeToast}>
      <img src="/Images/icclose36px.svg" alt={translate('webAccessibility.closeButton')} />
    </i>
  );
  return (
    <>
    <a className="skip-link" href='#contentReport' tabIndex='0'>{translate('webAccessibility.skipLink')}</a>
    <div className={`LayoutReport_wrap ${overFlow}`}>
      <div className="lrw_header_wrap">
        <Header setOverFlow={setOverFlow} type={translate('report.title')}/>
      </div>
        <ToastContainer
          bodyClassName="titleToast"
          closeButton={<CloseButton />}
        />
      <div id="contentReport" className="layout_content">{children}</div>
      <Footer />
    </div>
    </>
  );
};

export default withLocalize(LayoutReport);
