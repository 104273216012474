import queryString from 'querystring';
import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { HYUNDAI_SITE_ID } from '../../../constants';
import { selectAllMenu } from '../../../services/api';
import './style.scss';

const MenuWrap = styled.div`
  display: flex;
  align-items: center;
`;

const SanHead = styled.span`
  color: #676767;
  font-size: 16px;
  line-height: 49px;
  font-family: notoRegular;
  letter-spacing: -0.8px;
`;

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataMenu: [],
      pathName: null,
      id: null,
      loading: true,
      listMenu: null,
      activeClass: null,
      setUrlId: null,
      tabAction: null,
      dropdownActive: null,
      removeCSS: null,
      isVisibleSubMenu: false
    };
  }

  componentDidMount() {
    this.loadMenu(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activeLanguage.code !== nextProps.activeLanguage.code) {
      this.loadMenu(nextProps);
    }
  }

  loadMenu = async props => {
    let lang = props.activeLanguage.code;
    let query = queryString.parse(
      props.location.search.slice(1, props.location.search.length)
    );
    if (query && query.lang && ['kr', 'en'].includes(query.lang)) {
      lang = query.lang;
    }

    let urlSites = HYUNDAI_SITE_ID;
    let result = await selectAllMenu(urlSites, lang);
    this.setState({
      dataMenu: result.data.data.menuInfoTree,
      activeClass: lang === 'en' ? 'activeEng' : null
    });
  };

  onFocus() {
    var checkBoxComponent = document.getElementsByClassName('menu-item');
    for (let index = 0; index < checkBoxComponent.length; index++) {
      const element = checkBoxComponent[index];
      const target = element.querySelector('.children_menu');
      this.setState({isVisibleSubMenu: true});
      target.setAttribute('id', 'sub-menu sub-menu' + (index + 1));
    }
  }
  render() {
    // const history = this.props;
    const data = this.state.dataMenu;

    const { activeClass, tabAction } = this.state;
    return (
      <MenuWrap className={`menuWrap menu-wrap ${activeClass}`}>
        {data
          ? data.slice(0, 3).map((item, index) => {
              return (
                <div key={item.name} className='menu-item'>
                  <button
                    className={`${tabAction}`}
                    onFocus={() => {
                      this.setState({ tabAction: '' });
                      this.onFocus(index);
                    }}
                    onBlur={() => {this.setState({ isVisibleSubMenu: false });}}
                  >
                    <span className="link">{item.name}</span>
                  </button>
                  <div
                    className={`children_menu ${this.state.isVisibleSubMenu ? 'show': ''}`}
                  >
                    <div className="wrap-content" key={index}>
                      <ul className="content">
                        {item.children
                          ? item.children.map((item, index) => {
                              return (
                                <li key={index}>
                                  <a
                                    href={item.url}
                                    //title={item.name}
                                    className="child-menu"
                                    onClick={() => {
                                      this.setState({
                                        removeCSS: 'tab-item'
                                      });
                                    }}
                                    onFocus={() => {
                                      this.setState({ removeCSS: '' });
                                      this.setState({isVisibleSubMenu: true});
                                    }}
                                    onBlur={() => {
                                      this.setState({isVisibleSubMenu: false});
                                    }}
                                  >
                                    <SanHead className="content1">
                                      {item.name}
                                    </SanHead>
                                  </a>
                                </li>
                              );
                            })
                          : null}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
        <div
          // id="sub-menu"
          className={`dropdownList ${this.state.isVisibleSubMenu ? 'show' : ''}`}
          // onFocus={() => {
          //   document.getElementById('sub-menu').style.visibility = 'visible';
          //   document.getElementById('sub-menu').style.opacity = 1;
          //   document.getElementById('sub-menu').style.transform = 'translateY(0)';
          //   document.getElementById('sub-menu').style.zIndex = 3;
          // }}
          // onBlur={() => {
          //   document.getElementById('sub-menu').removeAttribute('style');
          // }}
        >
          <div
            style={{
              maxWidth: '1200px',
              width: '100%',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <div style={{ width: '20%' }}></div>
            <div
              className="dropdownList_wrap"
              onBlur={() => {
                this.setState({
                  tabAction: null
                });
              }}
            >
              <div className="row row_1">
                <div className="wrap-content"></div>
              </div>
            </div>
            <div style={{ width: '20%' }}></div>
          </div>
        </div>
      </MenuWrap>
    );
  }
}

export default withRouter(withLocalize(Menu));

// import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import styled from 'styled-components';
// import { withRouter } from 'react-router';
// import { withLocalize } from 'react-localize-redux';
// import { selectAllMenu } from '../../../services/api';
// import { HYUNDAI_SITE_ID } from '../../../constants';
// import queryString from 'querystring';
// import './style.scss';

// const MenuWrap = styled.div`
//   display: flex;
//   align-items: center;
// }
// `;

// const SanHead = styled.h2`
//     color: #676767;
//     font-size: 16px;
//     line-height: 49px;
//     font-family: notoRegular;
//     letter-spacing: -0.8px;
// `;

// class Menu extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       dataMenu: [],
//       pathName: null,
//       id: null,
//       loading: true,
//       listMenu: null,
//       activeClass: null,
//       setUrlId: null,
//       tabAction: null,
//       dropdownActive: null,
//       removeCSS: null,
//       newId: null
//     };
//   }

//   componentDidMount() {
//     this.loadMenu(this.props);
//   }

//   componentWillReceiveProps(nextProps) {
//     if (this.props.activeLanguage.code !== nextProps.activeLanguage.code) {
//       this.loadMenu(nextProps);
//     }
//   }

//   loadMenu = async props => {
//     let lang = props.activeLanguage.code;
//     let query = queryString.parse(
//       props.location.search.slice(1, props.location.search.length)
//     );
//     if (query && query.lang && ['kr', 'en'].includes(query.lang)) {
//       lang = query.lang;
//     }

//     let urlSites = HYUNDAI_SITE_ID;
//     let result = await selectAllMenu(urlSites, lang);
//     this.setState({
//       dataMenu: result.data.data.menuInfoTree,
//       activeClass: lang === 'en' ? 'activeEng' : null
//     });
//   };

//   onFocus(index){
//     var checkBoxComponent = document.getElementsByClassName('menu-item');
//     const element = checkBoxComponent[index];
//     console.log(element.children[0]);

//       element.children[1].setAttribute('id', 'sub-menu sub-menu' + (index + 1));
//       element.children[1].style.visibility = 'visible';
//       element.children[1].style.opacity = 1;
//       element.children[1].style.zIndex = 3;
//   }

//   render() {
//     const {translate} = this.props;
//     const data = this.state.dataMenu;

//     const { activeClass, tabAction, removeCSS, dropdownActive } = this.state;
//     return (
//       <MenuWrap className={`menuWrap menu-wrap ${activeClass}`}>
//         {data
//           ? data.slice(0, 3).map((item, index) => {
//               return (
//                 <a
//                   href="javascript: void(0);"
//                   className={`menu-item ${this.state.tabAction}`}
//                   key={index}
//                   title={item.name}
//                   onClick={() => {
//                     this.setState({ tabAction: 'tab-item' });
//                     return false;
//                   }}
//                   onFocus={() => {
//                     this.onFocus(index);
//                     this.setState({ tabAction: '' });
//                   }}
//                   onBlur={() => {
//                     var checkBoxComponent = document.getElementsByClassName('dropdownList');
//                     for (let index = 0; index < checkBoxComponent.length; index++) {
//                       const element = checkBoxComponent[index];
//                       element.removeAttribute('style');
//                     }
//                   }}
//                 >
//                   <h1 className="link">{item.name}</h1>
//                   <div
//                     id='sub-menu'
//                     className={`dropdownList ${dropdownActive}`}
//                     onBlur={() => {
//                       var checkBoxComponent = document.getElementsByClassName('dropdownList');
//                       for (let index = 0; index < checkBoxComponent.length; index++) {
//                         const element = checkBoxComponent[index];
//                         element.removeAttribute('style');
//                       }
//                     }}
//                   >
//                     <div
//                       className="dropdownList_wrap"
//                       onBlur={() => {
//                         this.setState({
//                           tabAction: null
//                         });
//                       }}
//                     >
//                       <div className="row row_1">
//                         <div className="wrap-content">
//                           <div className="content">
//                             {item.children
//                               ? item.children.map((item, index) => {
//                                   return (
//                                     <a
//                                       href={item.url}
//                                       key={index}
//                                       className={`${removeCSS}`}
//                                       title={item.name}
//                                       onClick={() => {
//                                         this.setState({
//                                           removeCSS: 'tab-item'
//                                         });
//                                       }}
//                                     >
//                                       <SanHead className="content1">
//                                         {item.name}
//                                       </SanHead>
//                                     </a>
//                                   );
//                                 })
//                               : null}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   {/* <div
//             className="dropdownList_wrap"
//             onBlur={() => {
//               this.setState({
//                 tabAction: null
//               });
//             }}
//           >
//             <div className="row row_1">
//               {data
//                 ? data.slice(0, 3).map((item, ind) => {
//                   return (
//                     <div className="wrap-content" key={ind}>
//                       <div className="content">
//                         {item.children
//                           ? item.children.map((item, index) => {
//                             return (
//                               <a
//                                 href={item.url}
//                                 key={index}
//                                 className={`${removeCSS}`}
//                                 onClick={() => {
//                                   this.setState({ removeCSS: 'tab-item' });
//                                 }}
//                                 onFocus={() => {
//                                   this.setState({ removeCSS: '' });
//                                 }}
//                               >
//                                 <SanHead className="content1">
//                                   {item.name}
//                                 </SanHead>
//                               </a>
//                             );
//                           })
//                           : null}
//                       </div>
//                     </div>
//                   );
//                 })
//                 : null}
//             </div>
//           </div> */}
//                 </a>
//               );
//             })
//           : null}
//         <div
//           // id="sub-menu"
//           // className={`dropdownList ${dropdownActive}`}
//           // onFocus={() => {
//           //   document.getElementById('sub-menu').style.visibility = 'visible';
//           //   document.getElementById('sub-menu').style.opacity = 1;
//           //   document.getElementById('sub-menu').style.transform =
//           //     'translateY(0)';
//           //   document.getElementById('sub-menu').style.zIndex = 3;
//           // }}
//           // onBlur={() => {
//           //   document.getElementById('sub-menu').removeAttribute('style');
//           // }}
//         >
//           <div
//             style={{
//               maxWidth: '1200px',
//               width: '100%',
//               margin: 'auto',
//               display: 'flex',
//               justifyContent: 'space-between'
//             }}
//           >
//             <div style={{ width: '20%' }}>
//               <img style={{ display: 'none' }} src="" alt="" title="" />
//             </div>
//             {/* <div
//             className="dropdownList_wrap"
//             onBlur={() => {
//               this.setState({
//                 tabAction: null
//               });
//             }}
//           >
//             <div className="row row_1">
//               {data
//                 ? data.slice(0, 3).map((item, ind) => {
//                   return (
//                     <div className="wrap-content" key={ind}>
//                       <div className="content">
//                         {item.children
//                           ? item.children.map((item, index) => {
//                             return (
//                               <a
//                                 href={item.url}
//                                 key={index}
//                                 className={`${removeCSS}`}
//                                 onClick={() => {
//                                   this.setState({ removeCSS: 'tab-item' });
//                                 }}
//                                 onFocus={() => {
//                                   this.setState({ removeCSS: '' });
//                                 }}
//                               >
//                                 <SanHead className="content1">
//                                   {item.name}
//                                 </SanHead>
//                               </a>
//                             );
//                           })
//                           : null}
//                       </div>
//                     </div>
//                   );
//                 })
//                 : null}
//             </div>
//           </div> */}
//             <div style={{ width: '20%' }}>
//               <img style={{ display: 'none' }} src="" alt="" title="" />
//             </div>
//           </div>
//         </div>
//       </MenuWrap>
//     );
//   }
// }

// export default withRouter(withLocalize(Menu));
