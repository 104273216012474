import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import styled from 'styled-components';
import { withLocalize } from 'react-localize-redux';
import './style.scss';

const Flex = styled.div`
  display: flex;
`;

const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  document.getElementById('backtotopPrivacy').blur();
};

const handleKeyPress=(event) =>{
  if(event.key === 'Enter'){      
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

const LayoutPrivacy = ({
  languages,
  activeLanguage,
  translate,
  setActiveLanguage,
  ...props
}) => {
  const { children } = props;
  let [overFlow, setOverFlow] = useState(null);

  useEffect(()=> {
    // if(localStorage.getItem('languageCode') === 'en'){
    //   document.getElementsByClassName('skip-link')[0].innerHTML = 'Skip to main';
    // }
    onkeypress = () =>{
      document.getElementById('contentPrivacy').click();
      document.getElementById('contentPrivacy').focus();
      document.getElementById('contentPrivacy').setAttribute('tabindex', '0');
      document.getElementsByClassName('lpw_header_wrap')[0].setAttribute('tabindex', '-1');
    }; 
  },[]);

  return (
    <>
    <a className="skip-link" href='#contentPrivacy'>{translate('webAccessibility.skipLink')}</a>
    <div className={`LayoutPrivacy_wrap ${overFlow}`} >
      <Helmet>
        <title>{translate('helmet.privacy')}</title>
      </Helmet>
      <div className="lpw_header_wrap">
        <Header setOverFlow={setOverFlow} type={translate('global.privacy_policy')}/>
      </div>
      <div className="layout_content" id="contentPrivacy">
        {children}
        <Flex className="backtotop_btn">
        <a href='javascript:void(0)' alt='' id='backtotopPrivacy' onClick={scrollTop} onKeyPress={handleKeyPress}>
          <img
            src="/Images/icarrowupward48px.svg"
            alt={translate('TooltipHome.btnToTop')}
            title={translate('TooltipHome.btnToTop')}
          />
          </a>
        </Flex>
      </div>
      <Footer />
    </div>
    </>
  );
};

export default withLocalize(LayoutPrivacy);
