import React, { useState, useEffect } from 'react';
import BannerContent from '../../components/BannerContent';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import styled from 'styled-components';
import BreadCrumb from '../../components/BreadCrumb';
import { withLocalize } from 'react-localize-redux';
import './style.scss';

const Flex = styled.div`
  display: flex;
`;

const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  document.getElementById('backtotopphilosophy').blur();
};
 const handleKeyPress=(event) =>{
  if(event.key === 'Enter'){      
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

const PhilosophyLayout = ({
  children,
  languages,
  activeLanguage,
  translate,
  setActiveLanguage,
  ...props
}) => {
  let [overFlow, setOverFlow] = useState(null);

  useEffect(()=> {
    // if(localStorage.getItem('languageCode') === 'en'){
    //   document.getElementsByClassName('skip-link')[0].innerHTML = 'Skip to main';
    // }
  },[]);

  return (
    <>
    <a className="skip-link" href='#contentPhilosophy' tabIndex='1'>{translate('webAccessibility.skipLink')}</a>
    <div className={`PhilosophyLayout ${overFlow}`}>
      <div className='pl_header_wrap'>
      <Header setOverFlow={setOverFlow} type={translate('philosophy.firstUrl')}/>
      </div>
      <div id='contentPhilosophy'>
        <BannerContent
          className="pl_banner"
          background="/Images/new_bg.png"
          title={translate('philosophy.titleBanner')}
          content={translate('philosophy.desBanner')}
        />
        <div className="PhilosophyLayout_content">
          <BreadCrumb
            firstUrl={translate('philosophy.firstUrl')}
            firstRouter='/philosophy'
            secondUrl={translate('philosophy.secondUrl')}
            secondRouter='/philosophy'
          />
          <div className="heading">{translate('philosophy.heading')}</div>
          <div className="picture">
            <img
              src={
                activeLanguage.code === 'kr'
                  ? '/Images/Group33007.png'
                  : '/Images/screenshot3.png'
              }
              alt={translate('webAccessibility.philoImg1Alt')}
              title={translate('webAccessibility.philoImg1Title')}
            ></img>
          </div>
          {children}
        </div>
      </div>
      <Flex className={`btn_totop ${activeLanguage.code === 'en' ? 'btn_en' : 'null'}`}>
      <a href='javascript: void(0)' tabIndex="0" id='backtotopphilosophy' onClick={scrollTop} onKeyPress={handleKeyPress}>
        <img
          src="/Images/icarrowupward48px.svg"
          alt={translate('TooltipHome.btnToTop')}
          title={translate('TooltipHome.btnToTop')}
        />
        </a>
      </Flex>
      <Footer />
    </div>
    </>
  );
};

export default withLocalize(PhilosophyLayout);
