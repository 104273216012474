import React, { useEffect, useState } from 'react';
import { Select } from '@material-ui/core';
import './style.scss';

export default ({
  name,
  textvalue,
  requirefield = false,
  class_selectbox,
  defaultValue,
  placeholder,
  data = [],
  titleSelect,
  onChange = () => {},
  onBlur = () => {},
  onFocus = () => {},
  ...props
}) => {
  let [selected, setSelected] = useState(defaultValue || props.value);
  
  return (
    <div className={`tt-select-box-wrap ${class_selectbox}`}>
      <span className="title">
        {textvalue} <b hidden={!requirefield}>*</b>
      </span>
      
      <Select
        native
        value={selected}
        placeholder={placeholder}
        inputProps={{
          name,
          id: name,
        }}
        onChange={(e) => {
          onChange(e.target.value);
          setSelected(e.target.value);
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        style={{ width: '100%' }}
        {...props}
      >
        {data.map(item => {
          return (
            <option value={item.id} key={item.id} >
              {item.value}
            </option>
          );
        })}
      </Select>
      {/* {generateLabel &&
        <label
          htmlFor={forLabel}
          style={{
            position: 'absolute',
            clip: 'rect(0,0,0,0)',
            width: '1px',
            height: '1px',
            margin: '-1px',
            overflow: 'hidden'
          }}
        >
          {labelTagTitle}
        </label>
      } */}
      <select
        onChange={event => {
          setSelected(event.target.value);
          props.onChange(event.target.value);
        }}
        value={selected}
        // id={forLabel}
        style={{position:'absolute', clip: 'rect(0,0,0,0)', width: '1px', height:'1px', margin: '-1px', overflow:'hidden'}}
        title={titleSelect}
        aria-hidden
        tabIndex={-1}
      >
        {(props.value !== undefined) ? (
          null
        ) : (
          <option selected></option>
        )}
        {data.map((item, index) => {
          return (
            <option selected={index == selected} value={item.id} key={item.id} title={item.value}>{item.value}</option>
          );
        })}
      </select>
    </div>
  );
};
